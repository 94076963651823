<template>
	<div class="SupervisionList">
		<w-navTab titleText="监督记录"></w-navTab>
		<div class="SupervisionList-head">
			<div class="head-nav">
				<div :class="['item-nav', { 'active-color': active == item.id }]" v-for="(item, index) in typeList" @click="onTypeList(item, index)">{{ item.title }}</div>
			</div>
		</div>
		<div class="SupervisionList-container">
			<w-list class="list-view sign-in-scroll" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<div class="container-item" v-for="item in list">
				<div class="item-title">
					<div class="title-text">{{item.type_name}}</div>
					<div class="title-status" v-if="item.status == 1">已提交</div>
					<div class="title-status color-yellow" v-if="item.status == 2">已受理</div>
				</div>
				<div class="item-content">{{item.content}}</div>
				<div class="item-picture-list">
					<div class="list-img" v-for="(vo,voIndex) in item.photos" @click="onPreview(item.photos, voIndex)"><img :src="vo" /></div>
				</div>
				<div class="item-incident-title">上报时间</div>
				<div class="item-incident-box">
					<div class="incident-box-icon"><img src="@/assets/img/icon50.png" alt="" /></div>
					<div class="incident-box-text">{{item.create_time}}</div>
				</div>
				<template v-if="item.is_secrecy != 2 && item.is_cooperate != 2">
					<div class="item-incident-title">上报人诉求</div>
					<div class="item-footer">
						<div class="footer-select" v-if="item.is_secrecy == 1"><van-checkbox v-model="checked" shape="square" disabled>需要保密</van-checkbox></div>
						<div class="footer-select" v-if="item.is_cooperate == 1"><van-checkbox v-model="checked" shape="square" disabled>愿意配合查处</van-checkbox></div>
					</div>
				</template>
				<template v-if="item.status == 2">
					<div class="item-incident-title title-top">处理结果</div>
					<div class="item-footer-text">
						<div>{{getText(item.reply_result)}}</div>
						<div>{{item.reply_content}}</div>
					</div>
				</template>
			</div>
			</w-list>
		</div>
	</div>
</template>

<script>
import Supervise from '@/api/supervise';
import Vant from '@/vendor/vant';
export default {
	name: 'SupervisionList',
	data() {
		return {
			active: 0,
			checked: true,
			typeList: [{ id: 0, title: '全部' }, { id: 1, title: '已提交' }, { id: 2, title: '已受理' }],
			page: 1,
			limit: 10,
			list: [],
			loading: true,
			finished: false,
			isEmpty: false,
		};
	},
	created() {
		this.onLoad();
		this.$store.commit('keep/removeKeep', 'AddSupervision');
	},
	methods: {
		onTypeList(item, index) {
			this.active = this.typeList[index].id;
			this.page = 1;
			this.finished = false;
			this.list = []
			this.onLoad();
		},
		// 获取列表
		onLoad() {
			let params = {
				page: this.page,
				limit: this.limit,
				status: this.active
			};
			Supervise.supRecords(params).then(
				result => {
					this.list = this.list.concat(result.data.lists);
					this.page++
					this.loading = false;
					this.finished = true;
					this.isEmpty = this.list.length <= 0;
				},
				error => {
					Vant.Toast(error.msg);
				}
			);
		},
		// 预览图片
		onPreview(vo, index) {
			Vant.ImagePreview({
				images: vo,
				startPosition: index,
			});
		},
		getText(index){
			let text = ['待核实','已核实，情况属实','已核实，未发现相关情况']
			return text[index - 1]
		}
	}
};
</script>

<style scoped lang="less">
.SupervisionList {
	background: #F5F6F8;
	min-height: 100vh;
	.SupervisionList-head {
		.head-nav {
			background: #fff;
			border-radius: 6px;
			padding: 8px;
			padding-bottom: 0;
			margin-bottom: 8px;
			display: flex;
			align-items: center;
			.item-nav {
				font-size: 15px;
				line-height: 22px;
				color: #1c1d1d;
				margin-right: 32px;
				padding-bottom: 8px;
				border-bottom: 2px solid #fff;
				&.active-color {
					color: #3377ff;
					border-bottom: 2px solid #3377ff;
				}
			}
		}
	}
	.SupervisionList-container {
		padding: 0 8px;
		.container-item {
			background: #fff;
			border-radius: 6px;
			padding: 0 12px;
			padding-bottom: 14px;
			margin-bottom: 8px;
			.item-title {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 12px 0;
				border-bottom: 1px solid #f5f6f8;
				.title-text {
					font-weight: bold;
					font-size: 15px;
					line-height: 22px;
					color: #1c1d1d;
				}
				.title-status {
					font-size: 13px;
					line-height: 19px;
					color: #3377ff;
					padding: 2px 10px;
					background: rgba(18, 138, 246, 0.1);
					border-radius: 2px;
					&.color-yellow{
						color: #FFB23F;
						background: rgba(255, 178, 63, 0.1);
					}
					&.color-grey{
						color: #777B81;
						background: #FAFAFA;
					}
				}
			}
			.item-content {
				font-size: 15px;
				line-height: 22px;
				color: #44484e;
				margin-top: 12px;
			}
			.item-picture-list {
				display: flex;
				align-items: center;
				.list-img {
					width: 161px;
					height: 109px;
					margin-right: 13px;
					border-radius: 2px;
					margin-top: 10px;
					img {
						width: 100%;
						height: 100%;
						border-radius: 2px;
						object-fit: cover;
					}
					&.list-img:last-child {
						margin-right: 0;
					}
				}
			}
			.item-incident-title {
				font-size: 15px;
				line-height: 22px;
				color: #1C1D1D;
				margin-top: 12px;
				&.title-top{
					margin-top: 24px;
					padding-top: 23px;
					border-top: 1px solid #F5F6F8;
				}
			}
			.item-footer-text{
				background: #FAFAFA;
				border-radius: 2px;
				padding: 6px;
				font-size: 14px;
				line-height: 20px;
				color: #44484E;
				margin-top: 8px;
			}
			.item-incident-box {
				margin-top: 8px;
				background: #fafafa;
				border-radius: 2px;
				padding: 8px;
				display: flex;
				align-items: center;
				.incident-box-icon {
					width: 16px;
					height: 16px;
					margin-right: 4px;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.incident-box-text {
					font-size: 14px;
					line-height: 20px;
					color: #44484e;
				}
			}

			.item-footer {
				margin-top: 8px;
				display: flex;
				align-items: center;
				.footer-select {
					margin-right: 16px;
					font-size: 14px;
					line-height: 20px;
					color: #777b81;
					/deep/.van-icon {
						border: 0;
						border-radius: 2px;
						color: #fff;
						background-color: #AAAAA9;
					}
				}
			}
		}
	}
}
</style>
